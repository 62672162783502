import "./App.css";
import React from "react";
import { Home, Generate, Setting } from "./Pages";
import {
  BrowserRouter as Router,
  HashRouter,
  Switch,
  Route,
} from "react-router-dom";

function App() {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/home" component={Home} />
        <Route exact path="/" component={Setting} />
        <Route exact path="/generate" component={Generate} />
      </Switch>
    </HashRouter>
  );
}

export default App;
