import axios from "axios";

var port = localStorage.getItem("port");
var ip = localStorage.getItem("ip");

if (ip == null) {
  window.location.reload();
}
export const API = axios.create({
  baseURL: `http://${ip}:${port !== null ? port : "19000"}/api/v1`,
});
