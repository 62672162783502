import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { API } from "../Config/api";
import { Nav, Break } from "../Components/";
import {
  Grid,
  Container,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Backdrop,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { getByDisplayValue } from "@testing-library/dom";
import {
  ErrorOutlineSharp,
  SettingsBrightnessOutlined,
  TrendingUpRounded,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function BasicTable() {
  const [form, setForm] = useState({
    types: "",
    os: "",
    names: "",
    from: "",
    end: "",
    page: 3,
    dateT: new Date(),
  });
  const [data, setData] = useState([]);
  const classes = useStyles();
  const [name, setName] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [type, setType] = useState([]);
  const [osType, setOs] = useState([]);
  const [totals, setTotals] = useState(0);
  const [pages, setPages] = useState(1);
  const [all, setAll] = useState(0);
  const [Arraysz, setArrays] = useState([]);
  const { types, os, names, from, end, dateT, page } = form;
  const [allCheck, setAllCheck] = useState(false);
  const [checkSingle, setCheckS] = useState([
    { id: 1, check: false },
    { id: 2, check: false },
    { id: 3, check: false },
    { id: 4, check: false },
    { id: 5, check: false },
    { id: 6, check: false },
    { id: 7, check: false },
    { id: 8, check: false },
    { id: 9, check: false },
    { id: 10, check: false },
  ]);

  const handleUp = () => {
    let dataTotal = Math.ceil(totals / 10);

    if (pages < dataTotal) {
      let pagez = pages + 1;
      setPages(pagez);
      loadData(pagez);
    }
  };

  const handleDown = () => {
    if (pages > 1) {
      let pagez = pages - 1;
      setPages(pagez);
      loadData(pagez);
    }
  };

  const loadData = async (pagez) => {
    try {
      setOpen(TrendingUpRounded);
      const res = await API.get(
        `/computer?name=${names}&ostype=${os}&type=${types}&from=${from}&end=${end}&page=${pagez}`
      );
      setData(res.data.arrays);
      setOpen(false);
      setTotals(res.data.total);

      setAll(Math.ceil(totals / 10));
    } catch (error) {
      console.log(error);
    }
  };

  const getValues = (e) => {
    let arraysNames = name;
    const result = arraysNames.find((item) => item === e.target.value);
    if (name.length > 0) {
      if (result) {
        const index = arraysNames.indexOf(e.target.value);
        arraysNames.splice(index, 1);
      } else {
        arraysNames.push(e.target.value);
      }
    } else {
      arraysNames.push(e.target.value);
    }

    setName(arraysNames);
    console.warn(name);
  };

  const getValuez = (e, index) => {
    const check = checkSingle[index];
    let arrayzz = [];
    const dumb = checkSingle.find((items) => items.id == index + 1);
    if (checkSingle[index].check == true) {
      if (dumb) {
        checkSingle.map((item) =>
          item.id == index + 1
            ? arrayzz.push({ id: item.id, check: false })
            : arrayzz.push({ id: item.id, check: item.check })
        );
        setCheckS(arrayzz);
      }
    } else if (checkSingle[index].check == false) {
      if (dumb) {
        checkSingle.map((item) =>
          item.id == index + 1
            ? arrayzz.push({ id: item.id, check: true })
            : arrayzz.push({ id: item.id, check: item.check })
        );
        setCheckS(arrayzz);
      }
    }
    let arey = [{ id: index + 1, check: false }];
    const less = checkSingle.find((item) => item === e.target.value);
    if (less) {
      checkSingle.splice(index + 1, 1);
    }

    let arraysNames = name;
    const result = arraysNames.find((item) => item === e.target.value);
    if (name.length > 0) {
      if (result) {
        const index = arraysNames.indexOf(e.target.value);
        arraysNames.splice(index, 1);
      } else {
        arraysNames.push(e.target.value);
      }
    } else {
      arraysNames.push(e.target.value);
    }

    setName(arraysNames);
    console.warn(name);
  };

  const getValuesAll = (e) => {
    if (allCheck == true) {
      setAllCheck(false);
      let datas = [
        { id: 1, check: false },
        { id: 2, check: false },
        { id: 3, check: false },
        { id: 4, check: false },
        { id: 5, check: false },
        { id: 6, check: false },
        { id: 7, check: false },
        { id: 8, check: false },
        { id: 9, check: false },
        { id: 10, check: false },
      ];
      setCheckS(datas);
      setName([]);
    } else {
      setAllCheck(true);
      setName(e.target.value.split(","));
      let datas = [
        { id: 1, check: true },
        { id: 2, check: true },
        { id: 3, check: true },
        { id: 4, check: true },
        { id: 5, check: true },
        { id: 6, check: true },
        { id: 7, check: true },
        { id: 8, check: true },
        { id: 9, check: true },
        { id: 10, check: true },
      ];
      setCheckS(datas);
      console.warn(name);
    }
  };

  const loadType = async () => {
    try {
      const res = await API.get("/type");
      const res1 = await API.get("/ostype");
      setOs(res1.data.recordset);
      setType(res.data.recordset);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadData(pages);
    loadType();
  }, [form, pages]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    setPages(1);
  };

  useEffect(() => {
    console.log(form);
  }, [form]);

  let ArraysAll = [];
  return (
    <>
      <Nav onChange={(e) => handleChange(e)} name="names" searchs={names} />

      <Break size={60} />

      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container maxWidth="lg" style={{ marginBottom: 20 }}>
        <Grid xs={12} container>
          <Grid xs={12} sm={2} container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                style={{ marginRight: 5 }}
                variant="contained"
                color="secondary"
                onClick={() => handleDown()}
              >
                Prev
              </Button>
              <p style={{ fontWeight: "bold" }}>
                {pages} / {Math.ceil(totals / 10)}
              </p>
              <Button
                style={{ marginLeft: 5 }}
                variant="contained"
                color="primary"
                onClick={() => handleUp()}
              >
                Next
              </Button>
            </div>
          </Grid>

          <Grid style={{ margin: 15 }} xs={12} sm={2}>
            <FormControl
              style={{ width: "100%", margin: "5%" }}
              className={classes.formControl}
            >
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                value={types}
                name="types"
                onChange={(e) => handleChange(e)}
                style={{ width: "100%" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
              >
                <MenuItem style={{ width: "100%" }} value="">
                  All
                </MenuItem>
                {type.map((item) => (
                  <MenuItem style={{ width: "100%" }} value={item.Type}>
                    {item.Type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid style={{ margin: 15 }} xs={12} sm={2}>
            <FormControl
              style={{ width: "100%", margin: "5%" }}
              className={classes.formControl}
            >
              <InputLabel id="demo-simple-select-label">
                Operating System
              </InputLabel>
              <Select
                value={os}
                name="os"
                onChange={(e) => handleChange(e)}
                style={{ width: "100%" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
              >
                <MenuItem style={{ width: "100%" }} value="">
                  All
                </MenuItem>

                {osType.map((item) => (
                  <MenuItem style={{ width: "100%" }} value={item.OSType}>
                    {item.OSType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid style={{ margin: 15 }} xs={12} sm={2}>
            <TextField
              style={{ width: "100%", margin: "5%" }}
              id="date"
              label="From"
              type="date"
              name="from"
              onChange={(e) => handleChange(e)}
              defaultValue={dateT}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid style={{ margin: 15 }} xs={12} sm={2}>
            <TextField
              style={{ width: "100%", margin: "5%" }}
              id="date"
              label="To"
              type="date"
              name="end"
              onChange={(e) => handleChange(e)}
              defaultValue={dateT}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid xs={12} lg={9} style={{ margin: 15 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ fontWeight: "bold" }}>
                Total Data : {totals}
              </Typography>
            </div>
          </Grid>

          <Grid xs={12} lg={2} style={{ margin: 5 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname: `generate`,
                  state: name,
                }}
              >
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#c0392b", color: "white" }}
                >
                  Generate QR
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>

        <TableContainer component={Paper}>
          <Table
            size="small"
            className={classes.table}
            aria-label="simple table"
          >
            <TableHead style={{ backgroundColor: "#fab1a0" }}>
              <TableRow>
                <TableCell align="left">No</TableCell>
                <TableCell align="left">Hostname</TableCell>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">Operating System</TableCell>
                <TableCell align="left">Last Scan Date</TableCell>
                <TableCell align="left">IP Address</TableCell>
                <TableCell align="left">
                  Print
                  <Checkbox
                    onChange={(e) => getValuesAll(e)}
                    value={data.map((item) => item.Computer_Idn)}
                    checked={allCheck}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={row.name}>
                  <TableCell align="left">
                    {pages == 1 ? index + 1 : index + 10 * (pages - 1) + 1}
                  </TableCell>
                  <TableCell align="left">{row.DisplayName}</TableCell>
                  <TableCell align="left">{row.CompType}</TableCell>
                  <TableCell align="left">{row.OSType}</TableCell>
                  <TableCell align="left">
                    {row.HWLastScanDate.substring(0, 10) +
                      " " +
                      row.HWLastScanDate.substring(11, 19)}
                  </TableCell>
                  <TableCell align="left">{row.Address}</TableCell>

                  <TableCell>
                    <Checkbox
                      inputProps={{ "aria-label": "primary checkbox" }}
                      onChange={(e) => getValuez(e, index)}
                      value={row.Computer_Idn}
                      type="checkbox"
                      checked={checkSingle[index].check}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
}
