import React, { useState, useEffect } from "react";
import { Button, Grid, Paper, TextField } from "@material-ui/core";
import { API } from "../Config/api";
import { useHistory } from "react-router-dom";

function Setting(props) {
  let history = useHistory();
  const [form, setForm] = useState({
    user: "",
    server: "",
    database: "",
    port: "",
    password: "",
    ip: "",
  });

  const { user, server, database, port, password, ip } = form;

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    console.log(form);
  }, [form]);

  const confirmData = () => {
    localStorage.setItem("port", port);
    localStorage.setItem("ip", ip);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (localStorage.getItem("ip") == null) {
      confirmData();
      window.location.reload();
      history.push("/home");
    }

    try {
      await API.post(
        `/files?ip=${ip}&user=${user}&server=${server}&database=${database}&port=${port}&password=${password}`
      );
      confirmData();
      history.push("/home");
      window.location.reload();
      alert("Berhasil Ubah Data");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Paper elevation={1} square>
        <div style={{ padding: 20 }}>
          <form onSubmit={(e) => handleSubmit(e)}>
            <p style={{ textAlign: "center" }}>Database Setting</p>
            <Grid style={{ margin: 15 }}>
              <TextField
                style={{ width: 350 }}
                id="standard-basic"
                label="User DB"
                name="user"
                value={user}
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid style={{ margin: 15 }}>
              <TextField
                style={{ width: 350 }}
                id="standard-basic"
                label="Password"
                name="password"
                type="password"
                value={password}
                onChange={(e) => handleChange(e)}
              />
            </Grid>

            <Grid style={{ margin: 15 }}>
              <TextField
                style={{ width: 350 }}
                id="standard-basic"
                label="Server"
                name="server"
                value={server}
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid style={{ margin: 15 }}>
              <TextField
                style={{ width: 350 }}
                id="standard-basic"
                label="Database"
                name="database"
                value={database}
                onChange={(e) => handleChange(e)}
              />
            </Grid>

            <p style={{ textAlign: "center" }}>Backend Setting</p>
            <Grid style={{ margin: 15 }}>
              <TextField
                style={{ width: 350 }}
                id="standard-basic"
                label="Port"
                name="port"
                value={port}
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid style={{ margin: 15 }}>
              <TextField
                style={{ width: 350 }}
                id="standard-basic"
                label="IP"
                name="ip"
                value={ip}
                onChange={(e) => handleChange(e)}
              />
            </Grid>

            <Grid style={{ margin: 15 }}>
              <Button
                style={{ width: "100%" }}
                variant="contained"
                color="primary"
                type="submit"
              >
                Submit
              </Button>
            </Grid>
          </form>
        </div>
      </Paper>
    </div>
  );
}

export default Setting;
