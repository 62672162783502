import React, { useState, useEffect } from "react";
import QRCode from "react-qr-code";
import "./style.css";
import { Nav, Break } from "../Components";
import { Grid, Container } from "@material-ui/core";
import { API } from "../Config/api";

function Generate(props) {
  const { state } = props.location;
  const [data, setData] = useState([]);

  const loadData = async () => {
    let arrayDevice = [];
    for (let i = 0; i < state.length; i++) {
      const res = await API.get(`/computer/${state[i]}`);
      let data = {
        value: state[i],
        DisplayName: res.data.recordset[0].DisplayName,
      };
      arrayDevice.push(data);
    }
    setData(arrayDevice);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Nav />

      <Break size={60} />

      <Container maxWidth="lg">
        <Grid container>
          {data.map((item, index) => (
            <Grid style={{ marginBottom: 25 }} xs={2} sm={2}>
              <div className="middle">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <QRCode
                    size={128}
                    value={`
                    ${item.value} 
                  `}
                  />
                  <p style={{ textAlign: "center" }}>{item.DisplayName}</p>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}

export default Generate;
